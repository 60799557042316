<mat-stepper [disableRipple]="true" #stepper labelPosition="bottom">
  <mat-step
    [label]="isRegenerate ? '3D Model Preprocessing' : '3D Model Preprocessing'"
    state=""
  >
  </mat-step>
  <mat-step
    [label]="isRegenerate ? 'Parameter Prediction' : 'Parameter Prediction`'"
  >
  </mat-step>
  <mat-step [label]="isRegenerate ? 'Gcode Regeneration' : 'Gcode Generation'">
  </mat-step>
  <mat-step [label]="isRegenerate ? 'Spliting' : 'Pull'"> </mat-step>

  <!-- Icon overrides. -->

  <ng-template matStepperIcon="edit">
    <mat-icon>more_horiz</mat-icon>
  </ng-template>
</mat-stepper>
