<div class="home-page-wrapper">
  <app-header></app-header>
  <app-breadcrum
    [content]="'Endless dreams, ageless imagination'"
  ></app-breadcrum>
  <div class="home-page-body">
    <mat-grid-list cols="10" rowHeight="5:5" [gutterSize]="'2rem'">
      <mat-grid-tile
        [colspan]="1"
        [rowspan]="4"
        class="center-title"
        routerLink="/printing-center"
        ><div>Printing Center</div></mat-grid-tile
      >
      <mat-grid-tile
        [colspan]="2"
        [rowspan]="2"
        class="list-item"
        routerLink="/inventory"
      >
        <div class="container">
          <div class="img-container ripple app app-bg-1">
            <img
              class="center-img"
              src="../../../assets/images/logo/center-library.png"
              alt=""
            />
          </div>
          <div class="title-container">Inventory</div>
        </div></mat-grid-tile
      >
      <mat-grid-tile [colspan]="2" [rowspan]="2" class="list-item"
        ><div class="container">
          <div
            class="img-container ripple app app-bg-2"
            routerLink="/printing-history"
          >
            <img
              class="center-img"
              src="../../../assets/images/logo/center-history.png"
              alt=""
            />
          </div>
          <div class="title-container">Print History</div>
        </div></mat-grid-tile
      >
      <mat-grid-tile [colspan]="5" [rowspan]="4" class="services-wrapper">
        <div class="services-container">
          <div class="service-item">
            <div class="icons-group">
              <!-- <div class="item">
                <img
                  src="../../../assets/images/logo/app-query-to-3d.png"
                  alt=""
                />
                <div class="name">Query to 3D</div>
              </div>
              <div class="item" routerLink="/regenerate">
                <img
                  src="../../../assets/images/logo/app-reconstruction.png"
                  alt=""
                />
                <div class="name">2D to 3D</div>
              </div> -->
              <div class="item" routerLink="/regenerate">
                <img
                  class="bg-app-red"
                  src="../../../assets/images/logo/app-face-reconstruction.png"
                  alt=""
                />
                <div class="name">Face Regeneration</div>
              </div>
            </div>
            <div class="icon-title" routerLink="/app">App</div>
          </div>
          <div class="service-item">
            <div class="icons-group">
              <div class="item" routerLink="/under-construction">
                <img
                  src="../../../assets/images/logo/printer-setting.png"
                  alt=""
                />
                <div class="name">Printer Settings</div>
              </div>
              <div class="item" routerLink="/under-construction">
                <img src="../../../assets/images/logo/materials.png" alt="" />
                <div class="name">Materials</div>
              </div>
              <div class="item" routerLink="/under-construction">
                <img
                  src="../../../assets/images/logo/slicer-settings.png"
                  alt=""
                />
                <div class="name">Slicer Settings</div>
              </div>
            </div>
            <div class="icon-title" routerLink="/printing-profile">
              Printing Profiles
            </div>
          </div>
          <div class="service-item">
            <div class="icons-group">
              <div class="item" routerLink="/under-construction">
                <img src="../../../assets/images/logo/community.png" alt="" />
                <div class="name">Community</div>
              </div>
              <div class="item" routerLink="/under-construction">
                <img src="../../../assets/images/logo/learning.png" alt="" />
                <div class="name">Learning</div>
              </div>
              <div class="item" *ngIf="user != null">
                <a
                  href="https://ht3dprint.com/category?user_id={{ user.id }}"
                  target="_blank"
                  ><img
                    src="../../../assets/images/logo/logo_utility.png"
                    alt=""
                  />
                  <div class="name">Library</div></a
                >
              </div>
              <div class="item" *ngIf="user == null">
                <img
                  src="../../../assets/images/logo/logo_utility.png"
                  alt=""
                />
                <div class="name">Search</div>
              </div>
            </div>
            <div class="icon-title" routerLink="/utilities">Utilities</div>
          </div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="2" class="list-item"
        ><div class="container">
          <div class="img-container ripple app app-bg-3">
            <img
              class="center-img"
              src="../../../assets/images/logo/center-farm.png"
              alt=""
              routerLink="warehouse"
            />
          </div>
          <div class="title-container">Printer Farm</div>
        </div></mat-grid-tile
      >
      <mat-grid-tile [colspan]="2" [rowspan]="2" class="list-item"
        ><div class="container" routerLink="/under-construction">
          <div class="img-container app app-bg-4">
            <img
              class="center-img"
              src="../../../assets/images/logo/center-queue.png"
              alt=""
            />
          </div>
          <div class="title-container">Queue</div>
        </div></mat-grid-tile
      >
    </mat-grid-list>
  </div>
  <app-footer></app-footer>
</div>
