<div class="home-page-wrapper">
  <app-header></app-header>
  <app-breadcrum
    [isBack]="true"
    (onClick)="this.router.navigate(['/'])"
    [content]="'Printing Center'"
  ></app-breadcrum>
  <div class="body-wrapper">
    <div class="services-container">
      <div class="service-item">
        <div class="icons-group">
          <div class="item" routerLink="/inventory">
            <img
              src="../../../../assets/images/logo/center-library-sm.png"
              alt=""
              class="bg-blue"
            />
            <div class="name">Inventory</div>
          </div>
          <div class="item" routerLink="/printing-history">
            <img
              src="../../../../assets/images/logo/center-history.png"
              alt=""
            />
            <div class="name">Print History</div>
          </div>
          <div class="item" routerLink="/warehouse">
            <img
              src="../../../../assets/images/logo/center-farm.png"
              routerLink=""
              alt=""
            />
            <div class="name">Printer Farm</div>
          </div>
          <div class="item" routerLink="/under-construction">
            <img src="../../../../assets/images/logo/center-queue.png" alt="" />
            <div class="name">Queue</div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
