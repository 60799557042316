<div class="inventory-page-wrapper">
  <app-header></app-header>
  <app-breadcrum (onClick)="navigateToInventory()" [isBack]="true" [content]="'Inventory'">
  </app-breadcrum>

  <div class="inventory-page-body">
    <mat-grid-list cols="20" rowHeight="2:1" [gutterSize]="'1em'">
      <mat-grid-tile colspan="1"></mat-grid-tile> <!--old is colspan="2" -->
      <mat-grid-tile class="custom-gcode-container white-bg" colspan="12" rowspan="15" *ngIf="!isIntended">
         <!-- old code: < class="canvas-container white-bg" colspan="10" rowspan="13" *ngIf="!isIntended"> -->
        <div class="change-gcode-mode" (click)="switch()">
          <app-spinner [isTransform]="true" *ngIf="showSpinner"></app-spinner>
        </div>

        <div #myObject class="myObject"></div>
      </mat-grid-tile>
      <mat-grid-tile class="canvas-container gcode-container white-bg" colspan="10" rowspan="13" *ngIf="isIntended">
        <div class="change-gcode-mode" (click)="switch()">
          <img src="../../../assets/images/switch-icon.png" alt="" />
        </div>
        <app-gcode-preview [link]="gcodeUrl"></app-gcode-preview>
      </mat-grid-tile>
      <mat-grid-tile colspan="1"></mat-grid-tile>

      <mat-grid-tile class="custom-printer-container white-bg" colspan="5" rowspan="13">
        <div class="center">
          <div class="setting-title">Settings</div>
          <form [formGroup]="printingForm">
            <div class="row">
              <div class="label">Choose 3D printer</div>

              <div class="input-field form-group">
                <select name="printer" id="" formControlName="printer" [ngModel]="selectedVal">
                  <option *ngFor="let p of printersList" value="{{ p.activationKey }}">
                    {{ p.name.trim() == "" ? "_" : p.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="label">Refer</div>
            </div>
            <div class="row">
              <div class="input-field select-field">
                <mat-radio-group formControlName="quality" [ngModel]="selectedQua">
                  <mat-radio-button value="speed" color="primary">Speed</mat-radio-button>
                  <mat-radio-button color="primary" value="fine">Fine</mat-radio-button>
                  <mat-radio-button color="primary" value="quality">Quality</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="row">
              <div class="label">Fill density</div>
              <div class="input-field">
                <input type="text" name="density" placeholder="25%" class="input body2" disabled />
              </div>
            </div>
            <div class="row">
              <div class="label">Material</div>
              <div class="input-field">
                <select name="material" formControlName="material" [ngModel]="selectedMat">
                  <option value="PLA" selected>PLA</option>
                  <option value="ABS">ABS</option>
                  <option value="PETG">PETG</option>
                </select>
              </div>
            </div>
            <div class="row">
              <!-- <div class="button">
                <button
                  mat-button
                  color="primary"
                  class="btn yellow-btn"
                  (click)="intend()"
                >
                  Intend
                </button>
              </div> -->
              <div class="button">
                <button class="ripple" btnHighlight label="Slicing" color="#ffffff" bgColor="#000000"
                  [isLoading]="isIntendLoading" (click)="intend()"></button>
              </div>
            </div>
            <div class="row">
              <div class="label">Intended Time</div>
              <!-- <div class="progress-bar" *ngIf="loaderService.isLoading | async">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div> -->
              <div class="label">{{ intendTime }}</div>
            </div>
            <div class="row">
              <div class="button">
                <button btnHighlight [disabled]="!isIntended" label="Print" color="#ffffff" bgColor="#000000"
                  [isLoading]="isPrintLoading" (click)="onSubmit()"></button>
              </div>
              <!-- <div class="button">
                <button
                  mat-button
                  color="primary"
                  class="btn black-btn"
                  (click)="onSubmit()"
                >
                  Print
                </button>
              </div> -->
            </div>
            <!--New Code: create download button-->
            <div class="row">
              <div class="button">
                <button btnHighlight [disabled]="!isIntended" label="Download" color="#ffffff" bgColor="#000000"
                  [isLoading]="isFileDownloading" (click)="download()"></button>
              </div>
            </div>
            <!--End off new codes-->
          </form>
        </div>
      </mat-grid-tile>
      <mat-grid-tile></mat-grid-tile>
    </mat-grid-list>
  </div>
</div>