<div *ngIf="!showSpinner" class="container">
  <app-header></app-header>
  <div class="profile-wrapper">
    <div class="avatar-wrapper">
      <div class="img">
        <form [formGroup]="uploadForm" (change)="onSubmit()">
          <input
            name="file"
            id="file"
            type="file"
            (change)="handleFileInput($event)"
            accept=".png,.jpg"
            formControlName="file"
            ngModel
            hidden
          />
        </form>
        <button
          onclick="document.getElementById('file').click();"
          width="45%"
          btnHighlight
          [label]="'Edit avatar'"
        ></button>
        <img
          [src]="user?.avatar_url"
          onerror="this.src='../../../assets/images/avatar.png'"
          alt="profile"
        />
      </div>
      <mat-tab-group class="mat-tab">
        <mat-tab class="tab-label" label="User information" color="secondary">
          <div class="info-wrapper">
            <div class="info-field">
              <div class="key">Username</div>
              <div class="value">{{ user?.username }}</div>
            </div>
            <div class="info-field">
              <div class="key">Email</div>
              <div class="value">{{ user?.email }}</div>
            </div>
            <div class="info-field">
              <div class="key">First name</div>
              <div class="value">
                {{ user?.firstName == "" ? "Not yet" : user?.firstName }}
              </div>
            </div>
            <div class="info-field">
              <div class="key">Last name</div>
              <div class="value">
                {{ user?.lastName == "" ? "Not yet" : user?.lastName }}
              </div>
            </div>
            <!-- <div class="info-field">
              <div class="key">Sex</div>
              <div class="value">{{ user?.sex }}</div>
            </div>
            <div class="info-field">
              <div class="key">Language</div>
              <div class="value">{{ user?.language }}</div>
            </div> -->

            <div class="info-field">
              <div class="key"></div>
              <div class="value">
                <!-- <button (click)="openDialog(false)" class="btn">Edit</button> -->
                <button
                  (click)="openDialog(false)"
                  width="100%"
                  btnHighlight
                  [label]="'Edit'"
                ></button>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Account">
          <div class="info-wrapper">
            <div class="info-field">
              <div class="key">Username</div>
              <div class="value">{{ user?.username }}</div>
            </div>
            <div class="info-field">
              <div class="key">Password</div>
              <div class="value">********</div>
            </div>
            <div class="info-field">
              <div class="key"></div>
              <div class="value">
                <button
                  (click)="openDialog(true)"
                  width="100%"
                  btnHighlight
                  [label]="'Change password'"
                ></button>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<app-spinner *ngIf="showSpinner"></app-spinner>
