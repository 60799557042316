<div class="login-page-wrapper">
  <div class="left-side-wrapper">
    <div class="header-wrapper">
      <div class="left-side-container">
        <div class="logo-wrapper">
          <img
            src="../../../assets/images/Logo.png"
            alt="Logo"
            routerLink="/"
          />
        </div>
        <div class="app-name-wrapper" routerLink="/">Horizon Tek</div>
      </div>
    </div>
    <div class="body-wrapper">
      <form
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit()"
        class="form-wrapper"
      >
        <div class="header">
          <div class="welcome">Welcome</div>
          <div class="sub">Please enter your detail</div>

          <!-- <img src="../../../assets/images/Button.png" alt="" /> -->
          <!-- <div class="">{{ message }}</div> -->
          <!-- <div class="or">Or</div> -->
        </div>
        <div
          class="input-wrapper"
          [ngClass]="{
            'invalid-input':
              loginForm.get('username')?.invalid &&
              loginForm.get('username')?.touched,
            'valid-input':
              loginForm.get('username')?.valid &&
              loginForm.get('username')?.touched
          }"
        >
          <input
            maxlength="25"
            (focus)="error = false"
            type="text"
            name="username"
            id=""
            placeholder="Username *"
            class="input"
            [ngClass]="{ 'input-error': submitted && f['username'].errors }"
            formControlName="username"
            (blur)="validateUsername()"
          />
        </div>

        <div
          class="input-wrapper"
          [ngClass]="{
            'invalid-input':
              loginForm.get('password')?.invalid &&
              loginForm.get('password')?.touched,
            'valid-input':
              loginForm.get('password')?.valid &&
              loginForm.get('password')?.touched
          }"
        >
          <input
            type="password"
            [type]="hide ? 'password' : 'text'"
            name="password"
            maxlength="25"
            id=""
            placeholder="Password *"
            class="input my-custom-level"
            formControlName="password"
            minlength="6"
            (blur)="validatePassword()"
          />
          <button
            class="show-pass-btn"
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
        </div>

        <button
          [disabled]="loginForm.invalid"
          btnHighlight
          label="Sign In"
          color="#ffffff"
          bgColor="#000000"
          width="100%"
          [isLoading]="isLoading"
          class="btn-wrapper"
        >
          Sign In
        </button>
        <div class="link" routerLink="/register">
          Don’t have an account? <strong>Sign Up</strong>
        </div>
      </form>
    </div>
  </div>
  <div class="right-side-wrapper">
    <img [src]="randomImage" alt="Cover image" />
  </div>
</div>
