<div class="home-page-wrapper">
  <app-header></app-header>
  <app-breadcrum
    [isBack]="true"
    (onClick)="this.router.navigate(['/'])"
    [content]="'App'"
  ></app-breadcrum>
  <div class="body-wrapper">
    <div class="services-container">
      <div class="service-item">
        <div class="icons-group">
          <div class="item" routerLink="/regenerate">
            <img
              class="bg-app-red"
              src="../../../assets/images/logo/app-face-reconstruction.png"
              alt=""
            />
            <div class="name">Face Regeneration</div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
