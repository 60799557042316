import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';


@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})

export class RegisterPageComponent {
  hide = true;
  hideConfirmPass = true;
  registerForm!: FormGroup;

  isLoading = false;
  images = [
    '../../../assets/images/Cover1.png',
    '../../../assets/images/Cover2.jpg',
    '../../../assets/images/Cover3.jpg',
    '../../../assets/images/Cover4.jpg',
    '../../../assets/images/Cover5.png',
    '../../../assets/images/Cover6.jpg',
    '../../../assets/images/Cover7.jpg',
  ]
  randomImage = this.images[0];
  constructor(

    public router: Router,
    private formBuilder: FormBuilder,

    private authService: AuthService,
    private tokenStorage: JwtService,

    private snackbarService: SnackbarService

  ) {
    this.randomImage = this.images[Math.floor(Math.random() * this.images.length)]
  }


  confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    return control.value.password === control.value.confirmPass
      ? null
      : { PasswordNoMatch: true };
  };
  ngOnInit(): void {

    this.registerForm = this.formBuilder.group({
      firstname: ['', [Validators.required, this.noWhitespaceValidator]],
      lastname: ['', [Validators.required, this.noWhitespaceValidator]],
      username: ['', [Validators.required, this.noWhitespaceValidator]],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: ['', Validators.required],
      confirmPass: ['', { validators: this.confirmPasswordValidator }],

    })

    if (this.tokenStorage.getToken()) {
      // this.isLoggedIn = true;
    }
  }

  public noWhitespaceValidator(control: AbstractControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }
  get f() { return this.registerForm.controls; }
  validateFirstname() {
    this.f['firstname'].invalid ? this.snackbarService.open('Incorrect firstname pattern', 'Cancel') : null
  }
  validateLastname() {
    this.f['lastname'].invalid ? this.snackbarService.open('Incorrect lastname pattern', 'Cancel') : null
  }
  validateUsername() {
    this.f['username'].invalid ? this.snackbarService.open('Empty username pattern', 'Cancel') : null

  }
  validateEmail() {
    this.f['email'].invalid ? this.snackbarService.open('Incorrect email pattern', 'Cancel') : null
  }
  validatePassword() {
    this.f['password'].invalid ? this.snackbarService.open('Password required more than 5 characters', 'Cancel') : null

  }
  validateConfirmPass() {
    if (this.f['confirmPass'].value !== this.f['password'].value) {
      this.snackbarService.open('Incorrect confirm password', 'Cancel')
    }
  }


  onSubmit() {
    let form = {
      firstName: this.f['firstname'].value.trim(),
      lastName: this.f['lastname'].value.trim(),
      username: this.f['username'].value.trim(),
      email: this.f['email'].value.trim(),
      password: this.f['password'].value.trim(),
    }
    console.log(form);

    if (this.registerForm.valid) {
      this.isLoading = true
      this.authService.register(form).subscribe(
        res => {
          if (res.code == 200) {
            this.snackbarService.success("Register success", "Cancel")
            this.router.navigate(['/login']);
            this.isLoading = false;
          }
        },
        err => {
          this.snackbarService.open(err.error.message);
          this.isLoading = false;
          this.registerForm.reset(
            {
              firstname: this.f['firstname'].value,
              lastname: this.f['lastname'].value,
              username: this.f['username'].value,
              email: this.f['email'].value
            }
          );//put after loading is false


        }
      )
    } else console.log('invalid');

  }


}
